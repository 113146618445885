

























































































import { updateMembership } from "@/api/membershipService";
import { computed, defineComponent, inject, PropType, reactive } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";
import SelectPlan from "./SelectPlan.vue";
import { useNotifier } from "@/providers/notifier";
import { toNOLocale } from "@/helpers/viewUtils";

export default defineComponent({
  name: "ChangeMembershipStepper",
  components: { SelectPlan },
  props: {
    membership: {
      type: Object as PropType<Learnlink.Membership.default>,
      required: true,
    },
    project: {
      type: Object as PropType<Learnlink.Project.FullViewApp>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const notify = useNotifier();

    const vm = reactive({
      pickedPlan: "",
      pickedProduct: {
        id: "",
        name: ""
      },
      step: 1,
      submitting: false
    });

    const currentPlanName = inject("currentPlanName") as string;

    const getMembership = inject<() => Promise<void>>("getMembership");

    const newPlan = computed(() => {
      return `${toNOLocale(parseInt(vm.pickedPlan.split("-")[0]) / 4)} timer pr. uke.`;
    });

    function cancel() {
      notify({
        title: "Avbrutt",
        message: "Ingen endring gjort",
        type: "success",
      });
      emit("cancel");
      vm.step = 1;
    }

    function choosePlan(planID: string) {
      vm.pickedPlan = planID;
      vm.step++;
    }

    function pickProduct(product: Learnlink.Membership.Product) {
      vm.pickedProduct = product;
      vm.step++;
    }

    async function update(): Promise<void> {
      vm.submitting = true;
      try {
        await updateMembership(props.project.customerUID, vm.pickedPlan);
        if (getMembership) await getMembership();
        notify({
          title: "Oppdatert!",
          message: "Det nye medlemskapet er i gang 🥳",
          type: "success",
        });
        emit("success");
      }
      catch (e) {
        notify({
          title: "Oops",
          message: "Noe gikk galt. Ta kontakt med support om problemet vedvarer",
          type: "error",
        });
      }
      vm.step = 1;
      vm.submitting = false;
    }

    return {
      cancel,
      choosePlan,
      currentPlanName,
      newPlan,
      pickProduct,
      update,
      vm,
    };
  },
});
