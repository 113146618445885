import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/app/courses";

export async function getCourse(courseID: string): Promise<Learnlink.Course.default> {
  const res = await learnlinkAPI.axios.get<Learnlink.Course.default>(
    `${ENDPOINT}/${courseID}`
  );
  return res.data;
}

export async function listCourses(): Promise<Learnlink.Course.default[]> {
  const res = await learnlinkAPI.axios.get<Learnlink.Course.default[]>(
    `${ENDPOINT}`
  );
  return res.data;
}

export async function listCourseModules(courseModuleIDList: string[]): Promise<Learnlink.CourseModule.default[]> {
  const res = await learnlinkAPI.axios.post<Learnlink.CourseModule.default[]>(
    `${ENDPOINT}/modules/search`,
    courseModuleIDList
  );
  return res.data;
}

export async function listCourseChapters(courseChapterIDList: string[]): Promise<Learnlink.CourseChapter.default[]> {
  const res = await learnlinkAPI.axios.post<Learnlink.CourseChapter.default[]>(
    `${ENDPOINT}/chapters/search`,
    courseChapterIDList
  );
  return res.data;
}
