
























import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "InterviewBooking",
  setup() {
    window.addEventListener && window.addEventListener("message", function(event) {
      if (event.origin === "https://learnlink.youcanbook.me") {
        const iframe = document.getElementById("ycbmiframelearnlink");
        if (iframe) iframe.style.height = event.data + "px";
      }
    }, false);
  }
});
