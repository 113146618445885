<template>
  <v-container v-if="!isLoading && requirements && requirements.length">
    <v-row
      v-for="item in requirements"
      :key="item.title"
    >
      <v-alert
        style="max-width: 620px; margin-bottom: 10px"
        color="warning"
        icon="mdi-alert-circle"
        shaped
      >
        <strong>{{ item.title }}</strong>
        <p class="info-text">
          {{ item.message }}
        </p>
      </v-alert>
    </v-row>
  </v-container>
</template>

<script>
import { userModule } from "@/store/modules/user";
import { getConnectedAccount } from "@/api/stripeService";
import { defineComponent } from "@vue/composition-api";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  name: "StripeRequirements",
  setup() {
    const { isLoading, data: requirements } = useAsyncData(() =>
      getConnectedAccount(userModule.state.userId)
    );

    return {
      isLoading,
      requirements,
    };
  },
});
</script>
