



































































































































import { computed, defineComponent, onMounted, reactive } from "@vue/composition-api";
import { mapWeekday, Weekday } from "@/helpers/timeUtils";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import EditProfile from "@/components/profile/EditProfile.vue";
import { listFiles } from "@/api/fileService";
import handleError from "@/helpers/errors";
import Uploader from "@/components/settings/upload/Uploader.vue";

export default defineComponent({
  components: { EditProfile, Uploader },
  name: "SellerOnboarding",
  setup() {
    const vm = reactive({
      loading: false,
      step: 1,
      weekdays: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
      ] as Weekday[],
    });

    const notify = useNotifier();

    const firstName = computed(() => userModule.state.profile.firstName);

    onMounted(async() => {
      const [certificates, identifications] = await Promise.all([
        listFiles("certificates", userModule.state.userId),
        listFiles("identifications", userModule.state.userId)
      ]);

      if (userModule.state.profile.aboutText && userModule.state.profile.photoURL) {
        vm.step = 4;
      }
      if (identifications.length) {
        vm.step = 5;
      }
      if (certificates.length) {
        vm.step = 6;
      }
    });

    const weekdays: Weekday[] = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
    ];

    function next(): void {
      vm.step++;
    }

    function prev(): void {
      vm.step--;
    }

    async function submit() {
      next();
      vm.loading = true;

      if (vm.step === 2) {
        try {
          await userModule.actions.updateUserProfile({
            weekdays: vm.weekdays
          });
        }
        catch (e) {
          handleError(e);
          notify({
            title: "Wops! Det skjedde en feil.",
            message: "Prøv igjen, eller kontakt support.",
            type: "error"
          });
        }
      }
      vm.loading = false;
    }

    return {
      firstName,
      mapWeekday,
      next,
      prev,
      submit,
      vm,
      weekdays,
    };
  },
});
