









































































































































import { computed, defineComponent, onBeforeUnmount, onMounted, PropType, provide, reactive } from "@vue/composition-api";
import { createLinks } from "@/helpers/stringUtils";
import { getReadableTimeForTeam } from "@/helpers/timeUtils";
import { getTeamSessions } from "@/api/teamSessionService";
import { getUnreadMessagesForTeam } from "@/api/messageService";
import { userModule } from "@/store/modules/user";
import ContractDialog from "./ContractDialog.vue";
import CourseContent from "@/components/courses/CourseContent.vue";
import FeedbackSeller from "@/components/seller/my-feedback/FeedbackSeller.vue";
import Learnlink from "@learnlink/interfaces";
import Moment from "moment";
import TeamCardHeader from "@/components/shared/TeamCardHeader.vue";
import TeamContract from "./TeamContract.vue";
import TeamInformationSeller from "./TeamInformationSeller.vue";
import TeamMembersSeller from "./TeamMembersSeller.vue";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  name: "TeamCardSeller",
  components: {
    ContractDialog,
    CourseContent,
    FeedbackSeller,
    TeamCardHeader,
    TeamContract,
    TeamInformationSeller,
    TeamMembersSeller,
  },
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.FullMetadata>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      newPrivateMessages: 0,
      showContractDialog: false,
      unreadMessages: {} as Record<string, number>,
    });

    let interval: number;

    onMounted(async () => {
      fetchUnreadMessages();

      // Fetch new messages every 2 minutes.
      interval = setInterval(() => {
        fetchUnreadMessages();
      }, 120000);

      // Clear new message fetching after 60 minutes,
      // in case someone leaves the browser open.
      setTimeout(() => {
        clearInterval(interval);
      }, 3600000);
    });

    onBeforeUnmount(() => {
      clearInterval(interval);
    });

    async function fetchUnreadMessages() {
      vm.newPrivateMessages = 0;
      vm.unreadMessages = await getUnreadMessagesForTeam(props.team.ID, userModule.state.userId);

      Object.values(vm.unreadMessages).forEach((val) => {
        vm.newPrivateMessages += val;
      });
    }

    const { data: dates } = useAsyncData(() => getTeamSessions(props.team.ID));
    const readableTime = computed(() => getReadableTimeForTeam(dates.value || [], props.team.time));

    const isEmploye = computed(() => userModule.state.user.employee);
    const sessionsLeft = computed(() => dates.value?.filter((session) => session.startTime > Moment().unix()).length || 0);

    const signedContract = computed(() => {
      const signatures = props.team.contractsSigned?.filter((signature) => signature.uid === userModule.state.userId);
      return signatures?.length > 0;
    });

    function openContract() {
      vm.showContractDialog = true;
    }

    function closeContract() {
      vm.showContractDialog = false;
    }

    provide("closeContract", closeContract);
    provide("fetchUnreadMessages", fetchUnreadMessages);
    provide("sessionsLeft", sessionsLeft);

    return {
      closeContract,
      createLinks,
      isEmploye,
      openContract,
      readableTime,
      sessionsLeft,
      signedContract,
      userModule,
      vm
    };
  }
});
