

























import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import { getTeamFeedback } from "@/api/feedbackService";
import FeedbackCard from "@/components/seller/my-feedback/FeedbackCard.vue";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "FeedbackSeller",
  components: { FeedbackCard },
  props: {
    teamID: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      loading: false,
      feedback: [] as Learnlink.Feedback.ProView[],
    });

    onMounted(async () => {
      vm.loading = true;
      vm.feedback = await getTeamFeedback(props.teamID);
      vm.loading = false;
    });

    return {
      vm,
    };
  }
});
