

























































import { defineComponent, PropType, reactive } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";
import AchievementUnlockingDialog from "@/components/achievements/AchievementUnlockingDialog.vue";
import Credentials from "@/components/Credentials.vue";
import Learnlink from "@learnlink/interfaces";
import ProfilePhoto from "@/components/profile/ProfilePhoto.vue";

export default defineComponent({
  name: "StudentInformation",
  components: { AchievementUnlockingDialog, ProfilePhoto, Credentials },
  props: {
    student: {
      type: Object as PropType<Learnlink.Student.ForTeam>,
      required: true,
    },
    team: {
      type: Object as PropType<Learnlink.Team.FullMetadata>,
      required: true,
    },
  },
  setup() {
    const vm = reactive({
      achievementUnlockingDialogVisible: false,
    });

    return {
      betaTester: userModule.state.user.betaTester,
      vm,
    };
  },
});
