
































import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import { formatUnixTimestamp } from "@/helpers/timeUtils";
import { useNotifier } from "@/providers/notifier";
import * as TeamSessionService from "@/api/teamSessionService";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "TeamSessionStudentPerformance",
  props: {
    teamSession: {
      type: Object as PropType<Learnlink.TeamSession.default>,
      required: true
    },
    studentUID: {
      type: String as PropType<string>,
      required: true,
    },
    students: {
      type: Array as PropType<string[]>,
      required: true,
    },
    performance: {
      type: Object as PropType<Learnlink.TeamSession.Performance>,
      default: undefined,
    },
  },
  setup(props) {
    const vm = reactive({
      attended: false,
      performance: {
        level: "" as Learnlink.Type.Level,
        comment: "",
      } as Learnlink.TeamSession.Performance,
    });
    const notify = useNotifier();

    const levels = ["high", "medium", "low"] as Learnlink.Type.Level[];
    enum levelsLocale {
      "high" = "Høyt nivå",
      "medium" = "Middels nivå",
      "low" = "Lavt nivå"
    }

    onMounted(() => {
      vm.attended = props.students?.includes(props.studentUID);
      vm.performance.comment = (props.performance?.comment) || "";
      vm.performance.level = (props.performance?.level) || "";
    });

    function readableTime() {
      return formatUnixTimestamp(props.teamSession.startTime, "dddd DD.MM.YYYY");
    }

    async function updateAttendance() {
      try {
        await TeamSessionService.updateAttendance({
          attended: vm.attended,
          studentUID: props.studentUID,
          teamSessionID: props.teamSession.ID
        });
        notify({
          title: "Oppdatert! 🥳",
          message: "",
          type: "success",
        });
      }
      catch (e) {
        notify({
          title: "Ooops! Noe gikk galt. 🤯",
          message: "Klarte ikke å oppdatere nivå eller kommentar. Kontakt support dersom problemet vedvarer.",
          type: "error",
        });
      }
    }

    async function updatePerformance() {
      try {
        await TeamSessionService.updatePerformance({
          performance: vm.performance,
          studentUID: props.studentUID,
          teamSessionID: props.teamSession.ID
        });
        notify({
          title: "Oppdatert! 🥳",
          message: "",
          type: "success",
        });
      }
      catch (e) {
        notify({
          title: "Ooops! Noe gikk galt. 🤯",
          message: "Klarte ikke å oppdatere nivå eller kommentar. Kontakt support dersom problemet vedvarer.",
          type: "error",
        });
      }
    }

    return {
      levels,
      levelsLocale,
      readableTime,
      updateAttendance,
      updatePerformance,
      vm,
    };
  }
});
