



















































































import { defineComponent, inject, onMounted, PropType, reactive } from "@vue/composition-api";
import CustomerDialog from "./CustomerDialog.vue";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";
import ProfilePhoto from "@/components/profile/ProfilePhoto.vue";
import TeamSessionStudentPerformance from "@/components/student/TeamSessionStudentPerformance.vue";
import { updateTeamSession } from "@/api/teamSessionService";

export default defineComponent({
  components: { TeamSessionStudentPerformance, ProfilePhoto, CustomerDialog },
  name: "TeamMembers",
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.FullMetadata>,
      required: true
    },
    unreadMessages: {
      type: Object as PropType<Record<string, number>>,
      required: true,
    }
  },
  setup(props) {
    const vm = reactive({
      currentTeamSession: {} as Learnlink.TeamSession.default,
      customerDialogVisible: false,
      loading: true,
      performanceMap: {} as Learnlink.TeamSession.PerformanceMap,
      previousTeamSession: {} as Learnlink.TeamSession.default,
      selectedCustomerUID: "",
      students: [] as string[],
    });

    const getMyTeams = inject<() => Promise<void>>("getMyTeams");

    function openCustomerDialog(parentUID: string) {
      vm.selectedCustomerUID = parentUID;
      vm.customerDialogVisible = true;
    }

    function getStudents(parentUID: string): Learnlink.Student.ForTeam[] {
      return props.team.verboseStudents.filter(student => student.parentUID === parentUID);
    }

    onMounted(async() => {
      const now = Moment().unix();
      const oneWeekAgo = Moment().subtract(7, "days").unix();

      const latestSessions = props.team.teamSessions.filter(session => {
        const sessionTime = Moment.unix(session.startTime).unix();
        return sessionTime <= now && sessionTime >= oneWeekAgo;
      });

      vm.currentTeamSession = latestSessions[latestSessions.length - 1];
      vm.previousTeamSession = latestSessions[latestSessions.length - 2];

      if (vm.currentTeamSession?.performance && !Object.keys(vm.currentTeamSession?.performance).length) {
        vm.previousTeamSession?.performance && await updateTeamSession({
          ID: vm.currentTeamSession.ID,
          performance: vm.previousTeamSession.performance,
        }) &&
        getMyTeams && await getMyTeams();
      }

      vm.students = vm.currentTeamSession?.students || [];
      vm.performanceMap = ((vm.currentTeamSession?.performance && Object.keys(vm.currentTeamSession?.performance).length > 0) && vm.currentTeamSession?.performance) ||
        ((vm.previousTeamSession?.performance && Object.keys(vm.previousTeamSession?.performance).length > 0) && vm.previousTeamSession?.performance) ||
        {};

      vm.loading = false;
    });

    return {
      getStudents,
      openCustomerDialog,
      vm,
    };
  },
});
