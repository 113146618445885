












































import { defineComponent, inject, PropType, reactive } from "@vue/composition-api";
import { postInfo } from "@/api/teamService";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import TeamInformation from "@/components/shared/TeamInformation.vue";

export default defineComponent({
  components: { TeamInformation },
  name: "TeamInformationSeller",
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.FullMetadata>,
      required: true
    }
  },
  setup(props) {
    const getMyTeams = inject<() => Promise<void>>("getMyTeams");
    const notify = useNotifier();

    const vm = reactive({
      loading: false,
      text: "",
    });

    async function submit() {
      vm.loading = true;

      try {
        await postInfo(props.team.ID, {
          author: userModule.state.userId,
          text: vm.text,
        });
        notify({
          title: "Sendt!",
          message: "Informasjonen ble sendt til alle i teamet 😄",
          type: "success"
        });
        vm.text = "";
        if (getMyTeams) await getMyTeams();
      }
      catch (e) {
        handleError(e);
        notify({
          title: "Nope!",
          message: "Det skjedde noe galt! Informasjonen ble ikke sendt!",
          type: "error"
        });
      }

      vm.loading = false;
    }

    return {
      submit,
      vm
    };
  }
});
