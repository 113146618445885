



















































import AchievementUnlockingCard from "@/components/achievements/AchievementUnlockingCard.vue";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, onMounted, PropType, reactive, ref, watchEffect } from "@vue/composition-api";
import { getAllAchievements } from "@/api/achievementService";

export default defineComponent({
  name: "AchievementUnlockingDialog",
  components: { AchievementUnlockingCard },
  props: {
    student: {
      type: Object as PropType<Learnlink.Student.ForTeam>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const vm = reactive({
      achievementsForDelegation: [] as Learnlink.Achievement.default[],
      loading: false,
    });

    onMounted(async() => {
      vm.loading = true;
      const studentsAchievements = {} as Record<string, Learnlink.Achievement.UserAchievement>;
      const achievements = await getAllAchievements();

      if (props.student.achievements?.length) {
        for (const myAchievement of props.student.achievements) {
          studentsAchievements[myAchievement.ID] = myAchievement;
        }
      }

      vm.achievementsForDelegation = achievements.map(achievement => {
        if (achievement.ID in studentsAchievements || !achievement.manualAssignment) return null;
        return achievement;
      }).filter(achievement => achievement) as Learnlink.Achievement.default[];
      vm.loading = false;
    });
    return {
      _value,
      vm,
    };
  },
});
