import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";
const ENDPOINT = "/app/feedback";

export async function getTeamFeedback(teamID: string): Promise<Learnlink.Feedback.ProView[]> {
  const feedbackRes = await learnlinkAPI.axios.get<Learnlink.Feedback.ProView[]>(
      `${ENDPOINT}/${teamID}`
  );
  return feedbackRes.data as Learnlink.Feedback.ProView[];
}
