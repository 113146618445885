




















































































































































import { computed, defineComponent, inject, PropType, reactive } from "@vue/composition-api";
import { getTeamSessions } from "@/api/teamSessionService";
import { signContract } from "@/api/teamService";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import handleError from "@/helpers/errors";
import Moment from "@/services/moment";
import useAsyncData from "@/hooks/useAsyncData";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "TeamContract",
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.FullMetadata>,
      required: true
    }
  },
  setup(props) {
    const getMyTeams = inject<() => Promise<void>>("getMyTeams");
    const closeContract = inject<() => void>("closeContract");

    const { data: sessions } = useAsyncData(() => getTeamSessions(props.team.ID));

    const vm = reactive({
      loading: false
    });

    const notify = useNotifier();

    const fullName = userModule.getters.getFullName();
    const dateTime = Moment().format("Do MMM YYYY HH:mm");

    const firstSessionReadableDate = computed(() => {
      if (sessions.value) {
        return Moment.unix(sessions.value[0].startTime).format("Do MMM YY");
      }
      return 0;
    });

    const lastSessionReadableDate = computed(() => {
      if (sessions.value) {
        return Moment.unix(sessions.value[sessions.value.length - 1].startTime).format("Do MMM YY");
      }
      return 0;
    });

    const signatureTime = computed(() => {
      const signatures = props.team.contractsSigned?.filter((signature) => signature.uid === userModule.state.userId);
      return signatures?.length > 0 ? Moment.unix(signatures[0].signed).format("Do MMM YYYY HH:mm") : 0;
    });

    const signatureLine = computed(() => `${fullName}, ${dateTime}`);

    async function sign() {
      vm.loading = true;

      try {
        await signContract(props.team.ID, userModule.state.userId);
        if (getMyTeams) await getMyTeams();
        if (closeContract) closeContract();
        notify({
          title: "Signert!",
          message: "Kontrakten er signert.",
          type: "success"
        });
      }
      catch (e) {
        handleError(e);
      }

      vm.loading = false;
    }

    return {
      firstSessionReadableDate,
      fullName,
      lastSessionReadableDate,
      sessions,
      signatureTime,
      sign,
      signatureLine,
      vm
    };
  }
});
