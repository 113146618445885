

























































import { userModule } from "@/store/modules/user";
import { defineComponent, PropType, inject, computed } from "@vue/composition-api";
import Moment from "moment";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "CancelMembershipStepOne",
  props: {
    membership: {
      type: Object as PropType<Learnlink.Membership.default>,
      required: true,
    },
  },
  setup(props) {
    const nextStep = inject("nextStep") as () => void;
    const setCancelMembershipDialogVisible = inject(
      "setCancelMembershipDialogVisible"
    ) as (val: boolean) => void;
    const readableEndTime = computed(() => {
      return props.membership.periodEnd ? Moment.unix(props.membership.periodEnd).format("Do MMMM YYYY") : "";
    });
    const seller = computed(() => userModule.state.user.seller);

    function continueMembership() {
      setCancelMembershipDialogVisible(false);
    }

    function cancelMembership() {
      nextStep();
    }

    return {
      cancelMembership,
      continueMembership,
      readableEndTime,
      seller,
    };
  },
});
