







































































import { defineComponent, ref, inject, computed, PropType } from "@vue/composition-api";
import { updateUser } from "@/api/userService";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import * as membershipService from "@/api/membershipService";
import axios from "axios";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "CancelMembershipStepFour",
  props: {
    customerUID: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props) {
    const nextStep = inject("nextStep") as () => void;
    const previousStep = inject("previousStap") as () => void;
    const setCancelMembershipDialogVisible = inject("setCancelMembershipDialogVisible") as (val: boolean) => void;
    const getMembership = inject("getMembership") as () => Promise<void>;

    const notify = useNotifier();
    const loading = ref(false);
    const quittingReason = ref("" as Learnlink.Type.QuittingReason);
    const seller = computed(() => userModule.state.user.seller);
    const dropdownOptions = [
      { text: "Vi skal ta pause fra undervisningen", value: "pause" },
      { text: "Vi har nådd målet vi satte oss", value: "reachedGoal" },
      { text: `${seller ? "Kunden" : "Vi"} får hjelp av noen andre`, value: "helpFromOthers" },
      { text: `${seller ? "Kunden" : "Vi"} er ikke fornøyd med tjenesten`, value: "notHappyWithTheService" },
      { text: `${seller ? "Eleven" : "Personlig lærer"} var ikke en god match`, value: "PLWasBadMatch" },
      { text: "Annet", value: "other" },
    ];
    const quittingDescription = ref("");

    async function cancelMembership() {
      loading.value = true;
      const uid = userModule.state.userId;

      try {
        await Promise.all([
          membershipService.cancelMembership(seller.value ? props.customerUID : userModule.state.userId),
          updateUser({
            quittingReason: quittingReason.value,
            quittingDescription: quittingDescription.value,
            uid,
          }),
        ]);
        if (seller.value) {
          notify({
            title: "Medlemskapet er avsluttet",
            message: "Dere kan fortsette med undervisning fram til medlemskapsperioden er over. " +
              "Hvis dere skal ha mer undervisning etter det må kunden få nytt medlemskap.",
            type: "success",
          });
          setCancelMembershipDialogVisible(false);
        }
        if (getMembership) await getMembership();
        nextStep();
      }
      catch (e) {
        handleError(e);
        if (axios.isAxiosError(e)) {
          notify({
            title: "Feil",
            message: e.response?.data,
            type: "error",
          });
        }
      }
      loading.value = false;
    }

    return {
      cancelMembership,
      dropdownOptions,
      loading,
      nextStep,
      previousStep,
      quittingDescription,
      quittingReason,
      seller,
      setCancelMembershipDialogVisible,
    };
  },
});
