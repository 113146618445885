








































import { defineComponent, PropType } from "@vue/composition-api";
import Credentials from "@/components/Credentials.vue";
import Learnlink from "@learnlink/interfaces";
import ProfilePhoto from "@/components/profile/ProfilePhoto.vue";

export default defineComponent({
  name: "ParentInformation",
  components: { ProfilePhoto, Credentials },
  props: {
    parent: {
      type: Object as PropType<Learnlink.Customer.ForVerboseTeam>,
      required: true
    }
  },
});
