



















import {
  defineComponent,
  PropType,
  ref,
  watchEffect,
} from "@vue/composition-api";
import ChangeMembershipStepper from "@/components/membership/ChangeMembershipDialog/ChangeMembershipStepper.vue";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "ChangeMembershipDialog",
  components: { ChangeMembershipStepper },
  props: {
    membership: {
      type: Object as PropType<Learnlink.Membership.default>,
      required: true,
    },
    project: {
      type: Object as PropType<Learnlink.Project.FullViewApp>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    return {
      _value,
    };
  },
});
