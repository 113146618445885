









































import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import { listCourseChapters } from "@/api/courseService";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "CourseModule",
  props: {
    module: {
      type: Object as PropType<Learnlink.CourseModule.default>,
      required: true
    }
  },
  setup(props) {
    const vm = reactive({
      chapters: [] as Learnlink.CourseChapter.default[],
      loading: true
    });

    onMounted(async () => {
      vm.chapters = await listCourseChapters(props.module.chapters);
      vm.loading = false;
    });

    return {
      vm
    };
  },
});
