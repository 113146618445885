






















































import { computed, defineComponent, PropType, reactive, ref, watchEffect } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";
import Messages from "@/components/Messages.vue";
import StudentInformation from "@/components/seller/my-teams/StudentInformation.vue";
import ParentInformation from "./ParentInformation.vue";

export default defineComponent({
  components: { Messages, StudentInformation, ParentInformation },
  name: "CustomerDialog",
  props: {
    customerUID: {
      type: String as PropType<string>,
      required: true
    },
    team: {
      type: Object as PropType<Learnlink.Team.FullMetadata>,
      required: true
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    }
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const vm = reactive({
      parentMap: {} as Record<string, Learnlink.Customer.ForVerboseTeam>,
    });

    props.team.verboseParents.forEach((parent) => {
      vm.parentMap[parent.uid] = parent;
    });

    const parent = computed(() => {
      return vm.parentMap[props.customerUID];
    });

    const students = computed(() => {
      return props.team.verboseStudents.filter((student) => student.parentUID === props.customerUID);
    });

    return {
      _value,
      parent,
      students,
      vm,
    };
  },
});
