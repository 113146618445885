







































































































































import { computed, defineComponent, inject, PropType, reactive } from "@vue/composition-api";
import { createMembership, listPlansForEndUser } from "@/api/membershipService";
import { toNOLocale } from "@/helpers/viewUtils";
import { useNotifier } from "@/providers/notifier";
import axios from "axios";
import CancelMembershipDialog from "@/components/membership/CancelMembershipDialog/CancelMembershipDialog.vue";
import ChangeMembershipDialog from "@/components/membership/ChangeMembershipDialog/ChangeMembershipDialog.vue";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  name: "MembershipInfoSeller",
  components: { CancelMembershipDialog, ChangeMembershipDialog },
  props: {
    activeMembership: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    membership: {
      type: Object as PropType<Learnlink.Membership.default>,
      required: true,
    },
    project: {
      type: Object as PropType<Learnlink.Project.FullViewApp>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      cancelMembershipDialogVisible: false,
      changeMembershipDialogVisible: false,
      loading: false,
      membershipPickerVisible: false,
      picked: "",
    });

    const { isLoading, data: plans } = useAsyncData(() =>
      listPlansForEndUser(),
    );

    const periodStart = props.membership.subscription?.current_period_start
      ? Moment.unix(props.membership.subscription?.current_period_start).format("Do MMM")
      : "";

    const periodEnd = props.membership.periodEnd
      ? Moment.unix(props.membership.periodEnd).format("Do MMM")
      : "";

    const endPrice = computed(() => {
      return (props.membership.subscription?.plan.amount || 0) / 100;
    });

    const productName = props.membership.product?.name;

    const weeklyLessons = computed(() => {
      return toNOLocale(props.membership.hours / 4);
    });

    const notify = useNotifier();
    const getMembership = inject<() => Promise<void>>("getMembership");

    async function startMembership() {
      vm.loading = true;
      try {
        await createMembership(props.project.customerUID, vm.picked);
        notify({
          title: "Medlemskap startet! 🥳",
          message: "Kunden er nå satt på medlemskap.",
          type: "success",
        });
        vm.picked = "";
        vm.membershipPickerVisible = false;
        if (getMembership) await getMembership();
      }
      catch (e) {
        handleError(e);
        if (axios.isAxiosError(e)) {
          notify({
            title: "Feil",
            message: e.response?.data,
            type: "error",
          });
        }
      }
      vm.loading = false;
    }

    return {
      endPrice,
      isLoading,
      periodEnd,
      periodStart,
      plans,
      productName,
      startMembership,
      vm,
      weeklyLessons
    };
  },
});
