


















import { defineComponent, PropType } from "@vue/composition-api";
import TeamCardSeller from "@/components/seller/my-teams/TeamCardSeller.vue";
import Learnlink from "@learnlink/interfaces";
import SupportPhoneAlert from "../SupportPhoneAlert.vue";

export default defineComponent({
  name: "MyTeamsSeller",
  components: { TeamCardSeller, SupportPhoneAlert },
  props: {
    teams: {
      type: Array as PropType<Learnlink.Team.FullMetadata[]>,
      required: true,
    }
  },
});
