
































import { computed, defineComponent, inject, PropType } from "@vue/composition-api";
import { unlockAchievementForStudent } from "@/api/achievementService";
import { useNotifier } from "@/providers/notifier";
import AchievementPhoto from "@/components/achievements/AchievementPhoto.vue";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "AchievementUnlockingCard",
  components: { AchievementPhoto },
  props: {
    achievement: {
      type: Object as PropType<Learnlink.Achievement.ForPresentation>,
      required: true,
    },
    student: {
      type: Object as PropType<Learnlink.Student.ForTeam>,
      required: true,
    }
  },
  setup(props, { emit }) {
    const notify = useNotifier();
    const getMyTeams = inject<() => Promise<void>>("getMyTeams");
    const achievementText = computed(() => props.achievement.achievedDescription
      .replace(/du/ig, props.student.firstName || "eleven")
      .replace(/deg/ig, props.student.firstName || "eleven"));
    async function unlockAchievement() {
      try {
        await unlockAchievementForStudent(props.student.uid, props.achievement.ID);
        if (getMyTeams) await getMyTeams();
        notify({
          title: "Suksess! 🥳",
          message: "Achievementen ble tildelt.",
          type: "success",
        });
        emit("success");
      }
      catch (e) {
        handleError(e);
        notify({
          title: "Klarte ikke å dele ut achievementen 🤯",
          message: "Kontakt support hvis problemet vedvarer.",
          type: "error",
        });
      }
    }
    return {
      achievementText,
      unlockAchievement,
    };
  },
});
