


















import { defineComponent, PropType } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";

export default defineComponent({
  name: "FeedbackCard",
  props: {
    feedback: {
      type: Object as PropType<Learnlink.Feedback.ProView>,
      required: true,
    },
  },
  setup() {
    return {
      Moment,
    };
  }
});
