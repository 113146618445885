









































































import { userModule } from "@/store/modules/user";
import { defineComponent, PropType, inject, onMounted } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "CancelMembershipStepTwo",
  props: {
    membership: {
      type: Object as PropType<Learnlink.Membership.default>,
      required: true,
    },
  },
  setup() {
    const nextStep = inject("nextStep") as () => void;
    const setCancelMembershipDialogVisible = inject(
      "setCancelMembershipDialogVisible"
    ) as (val: boolean) => void;

    function continueMembership() {
      setCancelMembershipDialogVisible(false);
    }

    function cancelMembership() {
      nextStep();
    }

    function openIntercom() {
      window.Intercom("showNewMessage", "Hei! Jeg er ikke helt fornøyd med: ");
    }

    onMounted(() => {
      if (userModule.state.user.seller) nextStep();
    });

    return {
      cancelMembership,
      continueMembership,
      openIntercom,
    };
  },
});
