





















import {
  defineComponent,
  PropType,
  reactive,
  inject,
} from "@vue/composition-api";
import { postOverrideRoomURLforProject } from "@/api/projectService";
import { postOverrideRoomURLforTeam } from "@/api/teamService";
import { useNotifier } from "@/providers/notifier";
import handleError from "@/helpers/errors";

export default defineComponent({
  name: "OverrideRoomURL",
  props: {
    overrideRoomUrl: {
      type: String as PropType<string>,
      required: true,
    },
    type: {
      type: String as PropType<string>,
      required: true,
    },
    projectId: {
      type: String as PropType<string>,
      required: true,
    }
  },
  setup(props) {
    const notify = useNotifier();

    const vm = reactive({
      loading: false,
      url: "",
      type: "",
    });

    const getProjects = inject<() => Promise<void>>("getProjects");
    const getMyTeams = inject<() => Promise<void>>("getMyTeams");

    async function submit() {
      vm.loading = true;

      const re = /^((http|https):\/\/)?(www\.)?([A-z]+)\.([A-z]{2,})/;
      if (props.type === "team") {
        if (re.test(vm.url)) {
          try {
            await postOverrideRoomURLforTeam(props.projectId, vm.url);
            notify({
              title: "Alt i orden! 😄",
              message: "Lenken har blitt oppdatert!",
              type: "success"
            });
            if (getMyTeams) getMyTeams();
          }
          catch (e) {
            handleError(e);
            notify({
              title: "Nope!",
              message: "Det skjedde noe galt! Lenken ble ikke oppdatert!",
              type: "error"
            });
          }
        }
        else if (!vm.url) {
          await postOverrideRoomURLforTeam(props.projectId, "");
          notify({
            title: "Den er grei!",
            message: "Lenken har blitt satt til et Learnlink-rom",
            type: "success"
          });
          if (getMyTeams) getMyTeams();
        }
        else {
          notify({
            title: "Oisann! 😓",
            message: "Det var et problem med lenken. Har du sjekket at den er skrevet riktig?",
            type: "error"
          });
        }
      }
      else {
        if (props.type === "project") {
          if (re.test(vm.url)) {
            try {
              await postOverrideRoomURLforProject(props.projectId, vm.url);
              notify({
                title: "Alt i orden! 😄",
                message: "Lenken har blitt oppdatert!",
                type: "success"
              });
              if (getProjects) getProjects();
            }
            catch (e) {
              handleError(e);
              notify({
                title: "Nope!",
                message: "Det skjedde noe galt! Lenken ble ikke oppdatert!",
                type: "error"
              });
            }
          }
          else if (!vm.url) {
            await postOverrideRoomURLforProject(props.projectId, "");
            notify({
              title: "Den er grei!",
              message: "Lenken har blitt satt til et Learnlink-rom",
              type: "success"
            });
            if (getProjects) getProjects();
          }
          else {
            notify({
              title: "Oisann! 😓",
              message: "Det var et problem med lenken. Har du sjekket at den er skrevet riktig?",
              type: "error"
            });
          }
        }
      }
    }

    return {
      submit,
      URL,
      vm,
    };
  },
});
