



































import { computed, defineComponent, inject, provide } from "@vue/composition-api";
import { getMyTeams } from "@/api/teamService";
import { RoutesConfig } from "@/router/Routes";
import { userModule } from "@/store/modules/user";
import { useRouter } from "@/router";
import BookInterview from "@/views/onboarding/sellers/BookInterview.vue";
import BookTraining from "@/views/onboarding/sellers/BookTraining.vue";
import LearnlinkRequirements from "@/components/profile/LearnlinkRequirements.vue";
import Moment from "@/services/moment";
import MyStudents from "@/components/seller/MyStudentsSeller.vue";
import MyTeams from "@/components/seller/MyTeamsSeller.vue";
import SellerOnboarding from "@/components/seller/SellerOnboarding.vue";
import StripeRequirements from "@/components/profile/StripeRequirements.vue";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  components: { LearnlinkRequirements, BookTraining, BookInterview, MyStudents, MyTeams, StripeRequirements, SellerOnboarding },
  name: "SellerDashboard",
  setup() {
    const { data: teams, refresh } = useAsyncData(() =>
      getMyTeams(userModule.state.userId)
    );
    provide("getMyTeams", refresh);
    const { navigate } = useRouter();

    const newUser = computed(() => {
      const twoDaysAgo = Moment().subtract(2, "days").unix();
      return userModule.state.user.registerDate > twoDaysAgo;
    });

    const proOnboarding = inject("proOnboardingActive");

    return {
      navigate,
      newUser,
      proOnboarding,
      RoutesConfig,
      teams,
      userModule,
    };
  }
});
