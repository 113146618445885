

































































































































































import { computed, defineComponent, PropType } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "DynamicSalary",
  props: {
    project: {
      type: Object as PropType<Learnlink.Project.FullViewApp>,
      required: true,
    }
  },
  setup(props) {
    const hoursSold = computed(() => Math.round(props.project.minutesSold / 60));
    const progressBarValue = computed(() => {
      if (props.project.minutesSold) {
        const hoursSqrd = Math.pow(hoursSold.value, 2);

        return Math.round(((-0.0038 * hoursSqrd) + (1.2813 * hoursSold.value) + 1.1461));
      }
      return 2;
    });

    return {
      hoursSold,
      progressBarValue
    };
  },
});
