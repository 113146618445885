





























import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import { getCourse, listCourseModules } from "@/api/courseService";
import CourseModule from "./CourseModule.vue";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  components: { CourseModule },
  name: "CourseContent",
  props: {
    courseID: {
      type: String as PropType<string>,
      required: true
    }
  },
  setup(props) {
    const vm = reactive({
      course: {} as Learnlink.Course.default,
      courseModules: [] as Learnlink.CourseModule.default[],
      loading: true
    });

    onMounted(async () => {
      vm.course = await getCourse(props.courseID);
      vm.courseModules = await listCourseModules(vm.course.modules);
      vm.loading = false;
    });

    return {
      vm
    };
  },
});
